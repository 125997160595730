export const environment = {
    production: true,
    builderRoute: "https://ines-builder.netlify.app",
    base_api: "https://jellyfish-app-sptdo.ondigitalocean.app",
    auth0: {
        domain: "dev-x55xstvihmtg4mre.us.auth0.com",
        clientId: "3nMHokEtEiQ6STtabJYrf8B80fc8DDwx",
        audience: "https://teachica-api",
        api_token: "",
    },
};
